import { getPosition, MetaSwiper, SafariSwiper } from "./meta-settings.js";
import Swiper from "./plugins/swiper-bundle.esm.browser.min.js";

const header = document.getElementsByClassName(`header`)[0];
// Header scroll
const scrollContainer = () => {
  return document.documentElement || document.body;
};

if (header && header.classList.contains("fixed")) {
  document.addEventListener("scroll", () => {
    if (scrollContainer().scrollTop > 0) {
      header.classList.add("scrolled");
    } else if (scrollContainer().scrollTop == 0) {
      header.classList.remove("scrolled");
    }
  });
}

// menu handlers

///check mobile menu show/hide condition
const mobileMenuStartPoint = +getComputedStyle(
  document.documentElement
).getPropertyValue("--mobile-menu-start-point");
let isMobileMenuEnable =
  $(window).outerWidth() <= mobileMenuStartPoint || $(".header-mobile").length;

$(".dropdown-toggle").click(function (e) {
  if (isMobileMenuEnable) {
    //close all opened sub menu
    $(".menu-item.dropdown.active .dropdown-menu").slideUp({
      complete: function () {
        $(this).closest(".dropdown").removeClass("active");
      },
    });

    //open current submenu
    $(this).closest(".menu-item.dropdown").toggleClass("active");
    if ($(this).closest(".menu-item.dropdown").hasClass("active")) {
      e.preventDefault();
      $(this).next(".dropdown-menu").slideDown();
    }
  }
});

// toggle menu handler
function menuToggle() {
  $(".menu-toggle").toggleClass("active");
  $(".navbar-nav").toggleClass("active");
  $(".header-close-wrapper").toggleClass("active");
  $(".logo-header").toggleClass("active");
  $(".btn-contact-us-header").toggleClass("active");
  // LockScroll when burger open and enable when closed and enable scroll on menu
  scrollLock.getScrollState()
    ? scrollLock.disablePageScroll(document.querySelector(".navbar-nav .menu"))
    : scrollLock.enablePageScroll();
}

function menuClose() {
  $(".menu-toggle").removeClass("active");
  $(".navbar-nav").removeClass("active");
  $(".header-close-wrapper").removeClass("active");
  $(".logo-header").removeClass("active");
  $(".btn-contact-us-header").removeClass("active");
  // LockScroll when burger open and enable when closed and enable scroll on menu
  scrollLock.clearQueueScrollLocks();
  scrollLock.enablePageScroll();
}

//menu update function
function updateMenu() {
  isMobileMenuEnable =
    $(window).outerWidth() <= mobileMenuStartPoint ||
    $(".mobile-header").length;
  if (!isMobileMenuEnable) {
    $(".dropdown-menu").css("display", "");
    $(".header-close-wrapper").removeClass("active");
    $(".menu-item.active").removeClass("active");
    $(".navbar-nav").removeClass("active");
    $(".menu-toggle").removeClass("active");
    // LockScroll when burger open and enable when closed
    scrollLock.enablePageScroll();
  }
}
$(window).on("resize orientationchange", updateMenu);
// end of toggle menu handler

$(".menu-toggle").click(menuToggle); //menu toggles
$(".menu-close-toggle").click(menuClose); //menu toggles
$(".header-close-wrapper").click(menuToggle); //menu toggles

$(".marquee-content").marquee({
  duration: 30000,
  duplicated: true,
  pauseOnHover: true,
  // direction: 'right',
  delayBeforeStart: 100,
  startVisible: true,
});

if (document.getElementsByClassName("default-select")[0]) {
  const defaultSelect = document.querySelectorAll(".default-select");

  for (let i = 0; i < defaultSelect.length; i++) {
    $(defaultSelect[i]).niceSelect();
  }
}

const titleContainer = document.getElementsByClassName("title-container")[0];

if (titleContainer) {
  const changingBoxArr = titleContainer.querySelectorAll(".changing-box");
  /*const time = 35;*/

  function textChangeFunc(changingElemsArr) {
    let count = 0;
    /*const timer = (time / changingElemsArr.length) * 1000;*/
    const timer = 4000;

    function toggleClass() {
      changingElemsArr[count].classList.remove("active");

      count = count < changingElemsArr.length - 1 ? count + 1 : 0;

      changingElemsArr[
        count
      ].parentElement.style.width = `${changingElemsArr[count].offsetWidth}px`;

      changingElemsArr[count].classList.add("active");
    }
    toggleClass();
    setInterval(toggleClass, timer);
  }

  for (let i = 0; i < changingBoxArr.length; i++) {
    const changingElemsArr =
      changingBoxArr[i].querySelectorAll(".changing-elem");
    titleContainer.classList.add("active");
    changingBoxArr[i].style.width = `${changingElemsArr[0].offsetWidth}px`;
    changingElemsArr[0].classList.add("active");
    let timeout;
    switch (i) {
      case 0:
        timeout = 1;
        break;

      case 1:
        timeout = 4;
        break;

      case 2:
        timeout = 3;
        break;

      case 3:
        timeout = 2;
        break;
    }

    setTimeout(function () {
      textChangeFunc(changingElemsArr);
    }, timeout * 1000);
  }
}

const industriesSlider =
  document.getElementsByClassName("industries-slider")[0];

if (industriesSlider) {
  const industriesSlider = new Swiper(".industries-slider", {
    spaceBetween: 10,
    slidesPerView: 1,
    grid: false,
    /*navigation: {
      nextEl: ".thumbs-slider__nav-next",
      prevEl: ".thumbs-slider__nav-prev",
    },*/
    allowTouchMove: true,
    /*pagination: {
      el: ".industries-pagination",
      type: "progressbar",
    },*/

    // Autoplay
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },

    scrollbar: {
      el: ".industries-pagination",
      draggable: true,
    },

    breakpoints: {
      652: {
        allowTouchMove: false,
        spaceBetween: 0,
        slidesPerView: "auto",
        autoplay: false,
        grid: {
          rows: 3,
          column: 2,
        },
      },
      pagination: false,
    },
  });
}

const latestJobsSlider =
  document.getElementsByClassName("latest-jobs-slider")[0];

if (latestJobsSlider) {
  const latestJobsSlidesArr =
    latestJobsSlider.querySelectorAll(".swiper-slide");
  let allowTouchMove = true;
  let autoplay = {
    delay: 4000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  };

  if (latestJobsSlidesArr.length <= 3 && window.innerWidth > 1630) {
    allowTouchMove = false;
    autoplay = false;
  } else if (latestJobsSlidesArr.length < 3 && window.innerWidth > 769) {
    allowTouchMove = false;
    autoplay = false;
  } else {
    window.addEventListener("scroll", handleScroll);
  }

  const latestJobsSwiper = new Swiper(".latest-jobs-slider", {
    spaceBetween: 10,
    slidesPerView: 1,
    /*navigation: {
      nextEl: ".thumbs-slider__nav-next",
      prevEl: ".thumbs-slider__nav-prev",
    },*/
    // pagination: {
    //   el: ".latest-jobs-pagination",
    //   type: "progressbar",
    //   draggable: true,
    // },

    scrollbar: {
      el: ".latest-jobs-pagination",
      draggable: true,
    },

    // Autoplay
    autoplay: autoplay,

    breakpoints: {
      652: {
        spaceBetween: 20,
        pagination: false,
      },
      770: {
        slidesPerView: 2,
        allowTouchMove: allowTouchMove,
      },
      1026: {
        allowTouchMove: allowTouchMove,
        slidesPerView: 2,
        spaceBetween: 30,
        pagination: false,
      },
    },
  });
  latestJobsSwiper.autoplay.stop();
  SafariSwiper(latestJobsSwiper);

  const swiperEl = document.querySelector(".latest-jobs-slider");
  const windowHeight = window.innerHeight;
  let autoplayStarted = false;

  function checkAutoplay() {
    const swiperTop = swiperEl.getBoundingClientRect().top;

    if (swiperTop <= windowHeight && !autoplayStarted) {
      latestJobsSwiper.autoplay.start();

      autoplayStarted = true;
    }
  }

  function handleScroll() {
    checkAutoplay();
  }
}

const ourEventsSlider = document.getElementsByClassName("our-events-slider")[0];

if (ourEventsSlider) {
  const ourEventsSwiper = new Swiper(".our-events-slider", {
    spaceBetween: 10,
    slidesPerView: 1,

    scrollbar: {
      el: ".our-events-pagination",
      draggable: true,
    },

    // Autoplay
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },

    breakpoints: {
      552: {
        spaceBetween: 20,
        slidesPerView: 2,
      },
      902: {
        slidesPerView: 3,
        spaceBetween: 16,
      },
      1026: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },
  });

  SafariSwiper(ourEventsSwiper);
}

if (document.getElementsByClassName("aura-card") && window.innerWidth > 551) {
  const auraCards = document.querySelectorAll(".aura-card");
  const auraCardsCursor = document.querySelectorAll(".aura-card .cursor-aura");

  if (window.innerWidth > 1025) {
    auraCards.forEach((card, i) => {
      card.addEventListener("mousemove", (e) => {
        const rect = card.getBoundingClientRect();
        let x = e.clientX;
        let y = e.clientY;
        auraCardsCursor[
          i
        ].style.transform = `translateX(${x}px) translateY(${y}px)`;
      });

      card.addEventListener("mouseenter", () => {
        auraCardsCursor[i].classList.add("active");
      });

      card.addEventListener("mouseleave", () => {
        auraCardsCursor[i].classList.remove("active");
      });
    });
  }
}

if (document.getElementsByClassName("technology-card")[0]) {
  const technologyCard = document.querySelectorAll(".technology-card");

  technologyCard.forEach((card, i) => {
    const technologyText = card.querySelector(".technology-card__text");
    const textContent = card.querySelector(".text-content");
    const text = card.querySelector(".text-content p");
    const moreBtn = card.querySelector(".btn-more");
    const textHeight = textContent.offsetHeight;

    const fontSize = $(text).css("font-size");
    const lineHeight = Math.floor(parseInt(fontSize.replace("px", "")) * 1.57);

    if (textHeight > lineHeight * 4) {
      moreBtn.classList.remove("more-hidden");
    }

    moreBtn.addEventListener("click", (e) => {
      moreBtn.classList.toggle("active");
      technologyText.classList.toggle("active");
    });
  });
}

if (document.getElementsByClassName("accordion-toggle")[0]) {
  const accordionToggle = document.querySelectorAll(".accordion-toggle");
  const accordionContent = document.querySelectorAll(".accordion-content");

  accordionToggle.forEach((elem, i) => {
    elem.addEventListener("click", () => {
      elem.classList.toggle("active");
      accordionContent[i].classList.toggle("active");
    });
  });
}

const doubleRangeSliderTooltips = () => {
  const doubleRange = document.getElementsByClassName(
    "double-range-tooltips"
  )[0];
  if (doubleRange) {
    const slider = doubleRange.querySelector("#double-range-tooltips");
    let max = +slider.dataset.max;
    if (max % 100 !== 0) {
      max = Math.round(+slider.dataset.max / 100) * 100 + 100;
    }
    const min = +slider.dataset.min;
    const unit = slider.dataset?.unit || "£";
    const step = +slider.dataset.step;
    const inputsHidden = doubleRange.querySelectorAll(
      ".double-range-hidden-input"
    );
    const startValueMin = +inputsHidden[0].value;
    let startValueMax = +inputsHidden[1].value;
    if (startValueMax % 100 !== 0) {
      startValueMax = Math.round(+inputsHidden[1].value / 100) * 100 + 100;
    }

    // how many percentages
    const borderLimit = 8;
    const borderDiff = 40 / borderLimit;

    noUiSlider.create(slider, {
      start: [startValueMin, startValueMax],
      connect: true,
      tooltips: true,
      margin: 10,
      step: step,
      range: {
        min: min,
        max: max,
      },
    });

    const tooltipsArr = slider.querySelectorAll(".noUi-tooltip");
    const circlesArr = slider.querySelectorAll(".noUi-origin");

    function returnTransform(element) {
      return element
        .replace(/[^0-9][^\d.]/g, "")
        .replace(")", "")
        .split(" ")
        .map((str) => {
          return Number(str);
        });
    }

    function needToMerge() {
      let tooltipOnePosition = tooltipsArr[0].getBoundingClientRect();
      let tooltipTwoPosition = tooltipsArr[1].getBoundingClientRect();

      if (
        tooltipsArr[0].classList.contains("hidden") ||
        tooltipsArr[1].classList.contains("hidden")
      ) {
        return true;
      }

      return (
        tooltipOnePosition.left +
          tooltipOnePosition.width -
          tooltipTwoPosition.left >
        0
      );
    }

    function resetTooltips(values) {
      mergeDiff = null;
      tooltipsArr.forEach((elem, index) => {
        elem.textContent = Math.round(values[index]) + " " + unit;
        elem.classList.remove("hidden");
      });
    }

    let trueTooltip = false;
    let mergeDiff = null;

    slider.noUiSlider.on("update", function (values, handle) {
      let translate = returnTransform(circlesArr[handle].style.transform)[0];
      let valueMin = returnTransform(circlesArr[0].style.transform)[0];
      let valueMax = returnTransform(circlesArr[1].style.transform)[0];

      let difference = valueMax - valueMin;

      inputsHidden[handle].value = Math.round(values[handle]);
      if (translate <= -100 + borderLimit) {
        tooltipsArr[0].style.transform = `translate(${
          -50 + Math.abs(translate + 100 - borderLimit) * borderDiff
        }%,100%)`;
      } else if (translate >= -borderLimit) {
        tooltipsArr[1].style.transform = `translate(${
          -50 - Math.abs(translate + borderLimit) * borderDiff
        }%,100%)`;
      } else {
        tooltipsArr[handle].style.transform = "translate(-50%,100%)";
      }

      if (needToMerge()) {
        if (
          !tooltipsArr[+!handle].classList.contains("hidden") &&
          !tooltipsArr[handle].classList.contains("hidden")
        ) {
          trueTooltip = handle;
          mergeDiff = difference;
          tooltipsArr[+!handle].classList.add("hidden");
        }

        if (trueTooltip) {
          tooltipsArr[trueTooltip].style.transform = `translate(${
            -50 - difference
          }%,100%)`;
        } else {
          // if grabbed right dot
          tooltipsArr[trueTooltip].style.transform = `translate(${
            -50 + difference
          }%,100%)`;
        }

        tooltipsArr[trueTooltip].textContent = `${
          Math.round(values[0]) + " " + unit
        } - ${Math.round(values[1]) + " " + unit}`;

        if (mergeDiff && difference > mergeDiff) {
          mergeDiff = null;
          resetTooltips(values);
        }
      } else {
        resetTooltips(values);
      }
    });

    slider.noUiSlider.on("end", function () {
      load("jobs/search", "form:#search_form", "form:#search_form2");
      return false;
    });
  }
};
doubleRangeSliderTooltips();

const contentSticky = document.getElementById("content-sticky");
const rightSidebar = document.getElementById("sidebar");
if (rightSidebar) {
  const sidebarBuffer = rightSidebar.querySelector(".sidebar__buffer");
  const sidebarInner = rightSidebar.querySelector(".sidebar__inner");
  // const headerHeight = +getRootStyle("--header-height").replace("px", "");
  const headerHeight = 0;

  // gap on top and bottom of sticky sidebar
  const gap = 30;

  let lastScrollTop = 0;

  // if flag true === scrolling down else scrolling up
  let flag = null;

  // if flagHeight true === sidebar is higher than viewport height
  let flagHeight =
    document.querySelector(".sidebar__inner").getBoundingClientRect().height >
    window.innerHeight - headerHeight;

  function calcHeightOfSidebar(flag) {
    const contentStickyTop = getPosition(contentSticky).y;
    const sidebarInnerTop = getPosition(sidebarInner).y;
    sidebarBuffer.style.height = `${sidebarInnerTop - contentStickyTop}px`;

    if (flag) {
      // scroll up
      sidebarInner.style.top = "";
      sidebarInner.style.bottom = `${
        -headerHeight -
        gap +
        window.innerHeight -
        sidebarInner.getBoundingClientRect().height
      }px`;
    } else {
      // scroll down
      sidebarInner.style.bottom = "";
      sidebarInner.style.top = `${
        Math.min(
          window.innerHeight - sidebarInner.getBoundingClientRect().height,
          headerHeight
        ) - gap
      }px`;
    }
    flagHeight =
      sidebarInner.getBoundingClientRect().height >
      window.innerHeight - headerHeight;
  }

  function resetSticky() {
    sidebarInner.style.bottom = "";
    sidebarInner.style.top = `${headerHeight + gap}px`;
    sidebarBuffer.style.height = "0";
  }

  if (!flagHeight) {
    resetSticky();
  } else {
    calcHeightOfSidebar(false);
  }

  window.addEventListener("scroll", function () {
    let st = window.pageYOffset || document.documentElement.scrollTop;

    if (!flagHeight) {
      resetSticky();
    }

    if (st > lastScrollTop && !flag) {
      // scroll down
      calcHeightOfSidebar(flag);
      flag = true;
    } else if (st < lastScrollTop && flag) {
      // scroll up
      calcHeightOfSidebar(flag);
      flag = false;
    }
    lastScrollTop = st <= 0 ? 0 : st;
  });

  window.addEventListener("resize", function (e) {
    if (!flagHeight) {
      resetSticky();
    }

    calcHeightOfSidebar(true);
    calcHeightOfSidebar(false);
  });
}

const btnFilters = document.getElementsByClassName("btn-filters")[0];
if (btnFilters) {
  const sidebar = document.querySelector(".sidebar");
  const jobsList = document.querySelector(".jobs-list");
  const jobsSearchContainer = document.querySelector(
    ".jobs-search-section__wrap"
  );
  const paginationSectionJobs = document.querySelector(
    ".result-section.jobs-page"
  );

  btnFilters.addEventListener("click", () => {
    btnFilters.classList.toggle("active");
    sidebar.classList.toggle("active");
    jobsList.classList.toggle("active");
    jobsSearchContainer.classList.toggle("active");
    paginationSectionJobs.classList.toggle("active");
  });
}

const scrollEvents = () => {
  const trueScrollTarget = document.getElementsByClassName("scroll-target")[0];

  if (trueScrollTarget) {
    const scrollTarget = document.querySelectorAll(".scroll-target");
    let decorsArr = [];

    if (document.getElementsByClassName("scroll-decor")[0]) {
      decorsArr = document.querySelectorAll(".scroll-decor");
    }

    if ("IntersectionObserver" in window) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry, i) => {
            if (entry.isIntersecting) {
              if (decorsArr.length !== 0) {
                decorsArr.forEach((elem, i) => {
                  if (entry.target === elem) {
                    elem.classList.add("animation");
                  }
                });
              }
            }
          });
        },
        {
          threshold: 0,
          rootMargin: "0% 0% -15% 0%",
        }
      );

      scrollTarget.forEach((target, index) => {
        observer.observe(target);
      });
    } else {
      /*$(window).on('scroll', function(){
        let  scrollToEL = $(window).scrollTop() + $(window).height() * 0.5;

        $('.scroll-target').each((ind, el)=>{
          let isIntersecting = scrollToEL > $(el).offset().top && $(el).offset().top + $(el).height() > scrollToEL
          if(isIntersecting){

            if($(el).hasClass('video-player') && !$(el).hasClass('active')){
              $(el).addClass('active');
              el.style.width = `${videoWidth}px`;
              el.style.height = `${videoHeight}px`;
              el.style.top = `${videoTop}px`;
              el.style.borderRadius = `10px`;
            }
            if($(el).hasClass('scroll-decor') && !$(el).hasClass('animation')){
              $(el).addClass("animation");
            }
          }
        })
      })
      $(window).trigger('scroll');*/
    }
  }
};

scrollEvents();

// Приклад приєднання lottie на проекті (BOLD треба приєднювати тепер просто .gif)
// lottie.loadAnimation({
//   container: document.getElementById(``),
//   renderer: "svg",
//   loop: true,
//   autoplay: true,
//   path: "./js/lottie/name.json",
// });
// test

if (document.getElementsByClassName("lottie-animation")[0]) {
  const lottieAnimationArr = document.querySelectorAll(".lottie-animation");

  let path = `app/public/lottie/animation.json`;

  if (document.querySelector(".lottie-animation-view")) {
    path = `../app/public/lottie/animation.json`;
  }

  for (let i = 0; i < lottieAnimationArr.length; i++) {
    let animation = lottie.loadAnimation({
      container: lottieAnimationArr[i],
      renderer: "svg",
      loop: false,
      autoplay: false,
      path: path,
    });
    let direction = -1;

    animation.setSpeed(0.8);
    animation.play();
    animation.addEventListener("complete", (e) => {
      if (direction === 1) {
        direction = -1;
      } else {
        direction = 1;
      }
      animation.setDirection(direction);
      animation.play();
      // animation.stop();
      // animation.setDirection(direction);
    });
  }
}

var elements = document.querySelectorAll(".force-sticky");
Stickyfill.forceSticky();
Stickyfill.add(elements);

var scroll = new SmoothScroll('a[href*="#"]');
